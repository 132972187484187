import { ReadonlyJSONObject, ReadTransaction, WriteTransaction } from "@workcanvascom/reflect";
import { canvasElementPrefix, getUnixTimestampUTC, textEnabledDefaults, validateSchema, authenticatedPut, authenticatedDelete, createElementId } from "../util/utils";
import { TextBlock, textBlockSchema } from "./schemas/textBlock";
import { Point } from "./schemas/canvas-element";
import consts from "../consts";
import { FontProperties } from "./schemas/textEnabled";

export const textBlockPrefix = `${canvasElementPrefix}textBlock-`;

export async function getTextBlock(tx: ReadTransaction, id: string): Promise<TextBlock | null> {
  const jv = await tx.get(key(id));
  if (!jv) {
    console.log(`Specified shape ${id} not found.`);
    return null;
  }
  return validateSchema(textBlockSchema, jv);
}

export async function moveTextBlock(
  tx: WriteTransaction,
  { id, dx, dy }: { id: string; dx: number; dy: number }
): Promise<void> {
  const textBlock = await getTextBlock(tx, id);
  if (textBlock) {
    textBlock.x += dx;
    textBlock.y += dy;
    await putTextBlock(tx, { id, textBlock });
  }
}

export async function updateText(
  tx: WriteTransaction,
  { id, text, cursorPosition }: { id: string; text: string; cursorPosition: number }
): Promise<void> {
  const textBlock = await getTextBlock(tx, id);
  if (textBlock) {
    textBlock.text = text;
    if (cursorPosition) {
      textBlock.cursorPosition = cursorPosition;
    }
    await putTextBlock(tx, { id, textBlock });
  }
}

export async function updateTextBlockColor(
  tx: WriteTransaction,
  { id, fill }: { id: string; fill: string }
): Promise<void> {
  const textBlock = await getTextBlock(tx, id);
  if (textBlock) {
    textBlock.textColor = fill;
    await putTextBlock(tx, { id, textBlock });
  }
}

export async function updateTextBlockFontSize(
  tx: WriteTransaction,
  { id, size }: { id: string; size: string }
): Promise<void> {
  const textBlock = await getTextBlock(tx, id);
  if (textBlock) {
    textBlock.fontSize = size;
    await putTextBlock(tx, { id, textBlock });
  }
}

export async function resizeTextBlock(
  tx: WriteTransaction,
  { id, width, height, textPosition }: { id: string; width?: number; height?: number; textPosition?: Point }
): Promise<void> {
  const textBlock = await getTextBlock(tx, id);
  if (textBlock) {
    if (width && height) {
      if (!isNaN(width) && !isNaN(height)) {
        textBlock.width = width;
        textBlock.height = height;
      }
      if (textPosition) {
        textBlock.textPosition.x = textPosition.x;
        textBlock.textPosition.y = textPosition.y;
      }
    }
    await putTextBlock(tx, { id, textBlock: textBlock });
  }
}

export function putTextBlock(
  tx: WriteTransaction,
  { id, textBlock }: { id: string; textBlock: TextBlock }
): Promise<void> {
  const next = { ...textBlock as ReadonlyJSONObject, lastModifiedTimestamp: getUnixTimestampUTC() };
  return authenticatedPut(tx, key(id), next);
}

export async function deleteTextBlock(tx: WriteTransaction, id: string): Promise<void> {
  await authenticatedDelete(tx, key(id));
}

function key(id: string): string {
  return `${textBlockPrefix}${id}`;
}

export function placeTextBlock(point: Point, text?: string) {
  const id = createElementId();
  return {
    id: id,
    textBlock: {
      type: "textBlock",
      id: id,
      x: point.x,
      y: point.y,
      ...textEnabledDefaults,
      text: text,
      width: 300,
      height: 43,
      attachedConnectors: {},
      textPosition: { x: 0, y: 0 },
      zIndexLastChangeTime: getUnixTimestampUTC(),
    } as TextBlock,
  };
}
