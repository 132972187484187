import { ReadonlyJSONObject, ReadTransaction, WriteTransaction } from "@workcanvascom/reflect";
import consts from "../consts";
import { canvasElementPrefix, getUnixTimestampUTC, textEnabledDefaults, validateSchema, authenticatedPut, createElementId } from "../util/utils";
import { Point } from "./schemas/canvas-element";
import { StickyNote, stickyNoteSchema } from "./schemas/sticky-note";

export const stickyNotePrefix = `${canvasElementPrefix}stickyNote-`;

export async function getStickyNote(tx: ReadTransaction, id: string): Promise<StickyNote | null> {
  const jv = await tx.get(key(id));
  if (!jv) {
    console.log(`Specified shape ${id} not found.`);
    return null;
  }
  return validateSchema(stickyNoteSchema, jv);
}

export async function moveStickyNote(
  tx: WriteTransaction,
  { id, dx, dy }: { id: string; dx: number; dy: number }
): Promise<void> {
  const stickyNote = await getStickyNote(tx, id);
  if (stickyNote) {
    stickyNote.x += dx;
    stickyNote.y += dy;
    await putStickyNote(tx, { id, stickyNote: stickyNote });
  }
}

export async function updateStickyNoteText(
  tx: WriteTransaction,
  { id, text, cursorPosition }: { id: string; text: string; cursorPosition?: number }
): Promise<void> {
  const stickyNote = await getStickyNote(tx, id);
  if (stickyNote) {
    stickyNote.text = text;
    if (cursorPosition) {
      stickyNote.cursorPosition = cursorPosition;
    }
    await putStickyNote(tx, { id, stickyNote: stickyNote });
  }
}

export async function updateStickyNoteColor(
  tx: WriteTransaction,
  { id, color }: { id: string; color: string }
): Promise<void> {
  const stickyNote = await getStickyNote(tx, id);
  if (stickyNote) {
    stickyNote.fill = color;
    await putStickyNote(tx, { id, stickyNote: stickyNote });
  }
}

export async function updateStickyNoteFontSize(
  tx: WriteTransaction,
  { id, size }: { id: string; size: string }
): Promise<void> {
  const stickyNote = await getStickyNote(tx, id);
  if (stickyNote) {
    stickyNote.fontSize = size;
    await putStickyNote(tx, { id, stickyNote: stickyNote });
  }
}

export function putStickyNote(
  tx: WriteTransaction,
  { id, stickyNote }: { id: string; stickyNote: StickyNote }
): Promise<void> {
  const next = { ...stickyNote as ReadonlyJSONObject, lastModifiedTimestamp: getUnixTimestampUTC() };
  return authenticatedPut(tx, key(id), next);
}

export async function resizeStickyNote(
  tx: WriteTransaction,
  {
    id,
    width,
    height,
    x,
    y,
    scaleX,
    scaleY,
  }: {
    id: string;
    width?: number;
    height?: number;
    x?: number;
    y?: number;
    scaleX?: number;
    scaleY?: number;
  }
): Promise<void> {
  const stickyNote = await getStickyNote(tx, id);
  if (stickyNote) {
    if (width && !isNaN(width!)) {
      stickyNote.width = width;
    }
    if (height && !isNaN(height!)) {
      stickyNote.height = height;
    }
    if (x && !isNaN(x!)) {
      stickyNote.x = x!;
    }
    if (y && !isNaN(y!)) {
      stickyNote.y = y!;
    }
    if (scaleX && !isNaN(scaleX!)) {
      stickyNote.scaleX = scaleX!;
    }
    if (scaleY && !isNaN(scaleY!)) {
      stickyNote.scaleY = scaleY!;
    }
    console.log("putStickyNote " + JSON.stringify(stickyNote));
    await putStickyNote(tx, { id, stickyNote });
  }
}

function key(id: string): string {
  return `${stickyNotePrefix}${id}`;
}

export function placeStickyNote(point: Point, fill: string) {
  return {
    id: createElementId(),
    stickyNote: {
      type: "stickyNote",
      x: point.x - consts.DEFAULTS.STICKY_NOTE_HEIGHT/2,
      y: point.y - consts.DEFAULTS.STICKY_NOTE_HEIGHT/2,
      width: consts.DEFAULTS.STICKY_NOTE_HEIGHT,
      height: consts.DEFAULTS.STICKY_NOTE_HEIGHT,
      fill: fill,
      scaleX: 1,
      scaleY: 1,
      attachedConnectors: {},
      zIndexLastChangeTime: getUnixTimestampUTC(),
      ...textEnabledDefaults,
      fontSize: consts.DEFAULTS.STICKY_NOTE_FONT_SIZE,
      align:"left",
      isWide: false,
    } as StickyNote,
  };
}
