import { ReadonlyJSONObject, ReadTransaction, WriteTransaction } from "@workcanvascom/reflect";
import { canvasElementPrefix, getUnixTimestampUTC, pixelsToSize, validateSchema, authenticatedPut, authenticatedDelete, createElementId } from "../util/utils";
import { Point } from "./schemas/canvas-element";
import { Drawing, drawingSchema } from "./schemas/drawing";

export const drawingPrefix = `${canvasElementPrefix}drawing-`;

export async function getDrawing(tx: ReadTransaction, id: string): Promise<Drawing | null> {
  const jv = await tx.get(key(id));
  if (!jv) {
    console.log(`Specified shape ${id} not found.`);
    return null;
  }
  return validateSchema(drawingSchema, jv);
}

export async function moveDrawing(
  tx: WriteTransaction,
  { id, dx, dy }: { id: string; dx: number; dy: number }
): Promise<void> {
  const drawing = await getDrawing(tx, id);
  if (drawing) {
    drawing.x += dx;
    drawing.y += dy;
    await putDrawing(tx, { id, drawing: drawing });
  }
}

export async function updateDrawingStroke(
  tx: WriteTransaction,
  { id, stroke }: { id: string; stroke: string }
): Promise<void> {
  const drawing = await getDrawing(tx, id);
  if (drawing) {
    drawing.stroke = stroke;
    await putDrawing(tx, { id, drawing: drawing });
  }
}

export async function updateDrawingStrokeWidth(
  tx: WriteTransaction,
  { id, strokeWidth }: { id: string; strokeWidth: string }
): Promise<void> {
  const drawing = await getDrawing(tx, id);
  if (drawing) {
    drawing.strokeWidth = strokeWidth;
    await putDrawing(tx, { id, drawing: drawing });
  }
}

export async function drawLine(tx: WriteTransaction, { id, point }: { id: string; point: Point }): Promise<void> {
  const lastLine = await getDrawing(tx, id);
  if (lastLine) {
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);
    await putDrawing(tx, { id, drawing: lastLine });
  }
}

export function putDrawing(tx: WriteTransaction, { id, drawing }: { id: string; drawing: Drawing }): Promise<void> {
  const next = { ...drawing as ReadonlyJSONObject, lastModifiedTimestamp: getUnixTimestampUTC() };
  return authenticatedPut(tx, key(id), next);
}

export async function resizeDrawing(
  tx: WriteTransaction,
  { id, x, y, scaleX, scaleY }: { id: string; x?: number; y?: number; scaleX: number; scaleY: number }
): Promise<void> {
  const drawing = await getDrawing(tx, id);
  if (drawing) {
    drawing.scaleX = scaleX;
    drawing.scaleY = scaleY;
    drawing.x = x!;
    drawing.y = y!;
    await putDrawing(tx, { id, drawing });
  }
}

export async function deleteDrawing(tx: WriteTransaction, id: string): Promise<void> {
  await authenticatedDelete(tx, key(id));
}

function key(id: string): string {
  return `${drawingPrefix}${id}`;
}

export function placeDrawing(point: Point, stroke: string, frameId: string | undefined) {
  const id = createElementId();
  const defaultWidth = "large";
  return {
    id: id,
    drawing: {
      type: "drawing",
      id: id,
      x: point.x,
      y: point.y,
      points: [0, 0],
      stroke: stroke,
      strokeWidth: defaultWidth,
      scaleX: 1,
      scaleY: 1,
      attachedConnectors: {},
      zIndexLastChangeTime: getUnixTimestampUTC(),
      frameId,
    } as Drawing,
  };
}

export function copyDrawing(node: any, offsetX?: number, offsetY?: number) {
  const id = createElementId();
  return {
    id,
    drawing: {
      type: "drawing",
      id,
      x: node.attrs.x + (offsetX ?? 20),
      y: node.attrs.y + (offsetY ?? 20),
      stroke: node.attrs.stroke,
      strokeWidth: pixelsToSize(node.attrs.strokeWidth, "width"),
      points: node.attrs.points,
      scaleX: node.attrs.scaleX,
      scaleY: node.attrs.scaleY,
      attachedConnectors: {},
      zIndexLastChangeTime: node.attrs.zIndexLastChangeTime,
    } as Drawing,
  };
}
