import { CanvasElement, Point } from "./schemas";
import { IntegrationItem, integrationItemSchema } from "./schemas/integration-item";
import { IntegrationType } from "../integrations/integration";
import { ReadonlyJSONObject, WriteTransaction } from "@workcanvascom/reflect";
import { canvasElementPrefix, getUnixTimestampUTC, authenticatedPut, createElementId } from "../util/utils";

export function placeIntegrationElement(
  { x, y }: Point,
  integrationId: string,
  integrationType: IntegrationType,
  configuration: any,
  baseOnElement?: CanvasElement
) {
  const id = createElementId();
  return {
    id,
    type: "integrationItem",
    item: integrationItemSchema.parse({
      ...(baseOnElement ?? {}),
      type: "integrationItem",
      x,
      y,
      scaleX: 1,
      scaleY: 1,
      zIndexLastChangeTime: getUnixTimestampUTC(),
      integrationId,
      integrationType,
      configuration,
    }) as IntegrationItem,
  };
}

export async function updateIntegrationItemConfiguration(
  tx: WriteTransaction,
  { id, configuration }: { id: string; configuration: any }
): Promise<void> {
  const element = await tx.get(key(id));
  if (element) {
    const { configuration: oldConfig, ...rest } = element as any;
    rest.configuration = { ...oldConfig, ...configuration };
    await putElement(tx, { id, element: rest });
  }
}

function putElement(tx: WriteTransaction, { id, element }: { id: string; element: IntegrationItem }): Promise<void> {
  const next = { ...(element as ReadonlyJSONObject), lastModifiedTimestamp: getUnixTimestampUTC() };
  return authenticatedPut(tx, key(id), next);
}

function key(id: string): string {
  return `${canvasElementPrefix}${id}`;
}
