import { ExperimentalDiffOperationChange, Reflect } from "@workcanvascom/reflect/client";
import { M, mutators as replicacheMutators } from "shared/datamodel/mutators";

export function createReplicacheInstance(
  userId: string,
  documentId: any,
  repsToken: string,
  isAnonymous: boolean = false,
  debugWorker: boolean = false,
  onOnlineChange?: (online: boolean) => void
): Reflect<M> {
  const workerOrigin = debugWorker ? process.env.PUBLIC_WORKER_HOST_DEBUG! : process.env.PUBLIC_WORKER_HOST!;

  console.info(`Connecting to worker at ${workerOrigin}`);
  const r = new Reflect<M>({
    socketOrigin: workerOrigin,
    userID: userId,
    roomID: documentId,
    auth: JSON.stringify({
      authToken: repsToken,
      userId: userId,
    }),
    mutators: replicacheMutators,
    logLevel: 'debug',
    onOnlineChange: onOnlineChange,
    hiddenTabDisconnectDelay: 1000 * 60 * 10, // 10 minutes
    enableAnalytics: true,
  });

  return r;
}

export async function fillBoardContent(
  userId: string,
  destinationDocumentId: string,
  repsToken: string,
  content: Record<string, any>
) {
  const destinationReps = createReplicacheInstance(userId, destinationDocumentId, repsToken, false, false);
  const elements = Object.entries(content).map(([key, value]) => {
    return {
      id: key,
      value,
    };
  });
  destinationReps.mutate.putManyWithSignal(elements);
  await new Promise<void>((resolve) => {
    destinationReps.experimentalWatch((diff) => {
      const controlKey = diff.find(
        (el) => el.op === "change" && el.key === "control/completed-signal"
      ) as ExperimentalDiffOperationChange<string>;
      if (controlKey && controlKey.newValue === "completed") {
        resolve();
        destinationReps.close();
      }
    });
  });
}
