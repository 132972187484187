import { WriteTransaction } from "@workcanvascom/reflect";
import { authenticatedDelete, authenticatedPut } from "../util/utils";

export function putAny(tx: WriteTransaction, { id, value }: { id: string; value: any }): Promise<void> {
  return authenticatedPut(tx, id, value);
}

export async function putMany(tx: WriteTransaction, items: { id: string; value: any }[]) {
  await Promise.all(
    items.map((item) => {
      return authenticatedPut(tx, item.id, item.value);
    })
  );
}

export async function deleteMany(tx: WriteTransaction, ids: string[]) {
  await Promise.all(
    ids.map((id) => {
      return authenticatedDelete(tx, id);
    })
  );
}

const mutatorEnv: "client" | "server" = typeof window !== "undefined" ? "client" : "server";
export async function putManyWithSignal(tx: WriteTransaction, items: { id: string; value: any }[]) {
  await putMany(tx, items);
  authenticatedPut(tx, "control/completed-signal", mutatorEnv === "server" ? "completed" : "pending");
}
